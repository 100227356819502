import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesConsumer } from '../../../../../../../libs/env/src/index';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { envSalesDev } from '../../../../../../../libs/env/src/index';
import { env as baseEnv } from './env';

/** DON'T USE IN ANY MODULES. THIS IS WHAT `@ecp/env` ALIAS RESOLVES TO AT BUILD TIME. */
export const env: EnvironmentSalesConsumer = merge({}, envSalesDev, baseEnv, {
  caConnectRedirectAutoUrl: new URL(
    'https://onstarinsurance-auto-dev.connectbyamfam.com/auto/auto/auto?PartnerID=OSI&refId=11m-j10#PolicyholderDetails',
  ),
  caConnectRedirectHomeUrl: new URL(
    'https://onstarinsurance-home-dev.connectbyamfam.com/home/aah/residenceaddress?PartnerID=OSI&refId=11m-j10&ho_prod=H',
  ),
  caConnectRedirectAutoRetrieveUrl: new URL(
    'https://onstarinsurance-auto-dev.connectbyamfam.com/auto/auto/retrieveyourquote?PartnerID=OSI&refId=11m-j10#retrieveyourquote',
  ),
  caConnectRedirectHomeRetrieveUrl: new URL(
    'https://onstarinsurance-home-dev.connectbyamfam.com/home/aah/retrieveyourquote?PartnerID=OSI&showRetrieve=true&refId=11m-j10&ho_prod=H',
  ),
  optimizely: {
    sdkKey: '3WduHj5uomHDj9P4n7BTT',
  },
}) as EnvironmentSalesConsumer;
