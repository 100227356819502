import { merge } from '@ecp/utils/common';

import { LineOfBusiness } from '@ecp/features/shared/product';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesConsumerShared } from '../../../../../../../libs/env/src/index';
import { env as baseEnv } from '../env';

export const env: EnvironmentSalesConsumerShared = merge({}, baseEnv, {
  static: {
    expId: '7319',
    partnerId: '536',
    title: 'General Motors Insurance',
    eligibleProducts: ['homesite.home'],
    defaultLineOfBusiness: LineOfBusiness.HOME,
  },
  afiAppname: 'gmi-monoline-renters',
  afiApiKey: 'aa3435c9-8e19-413e-9fab-484b5f12aa83',
  sapiClientId: 'OnY74gJMpeUQEx6M4mNScjZxswE8h9Up',
  sapiClientSecret: 'DfU6Ntjy6xMkFrQ51pie8N53Htvtz1qQZTgbvgZNx5WIJbwJNWg2I5nGwA9iTU7m',
});
